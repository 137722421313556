.pulser {
    width: 5rem;
    height: 5rem;
    background: #8FB569;
    border-radius: 50%;
    position: relative;
}

.pulser::after {
    animation: pulse 1000ms cubic-bezier(0.9, 0.7, 0.5, 0.9) infinite;
}

@keyframes pulse {
    0% {
        opacity: 0;
    }

    50% {
        transform: scale(1.4);
        opacity: 0.4;
    }
}

.pulser::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #8fb569eb;
    border-radius: 50%;
    z-index: -1;
}